/* src/components/UserNode.css */
.user-node {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  background-color: #f9f9f9;
  position: relative;
}

.user-info {
  margin-bottom: 10px;
}
.metaInfo button {
  background-color: #1B1B1B;
  border: 1px solid #ffd452;
  color: #ffd452;
  padding: 5px 20px;
  font-size: 15px;
  border-radius: 5px;
}
.user-children {
  margin-left: 20px;
}
/* src/components/UserTree.css */
.user-tree {

  margin: 20px;
}
.left_class{
  display: inline-block;
}
.user-tree{
  background: #fff;
}
.user-info{
  margin: 0px auto;
}
.user-bord{
  padding: 10px;
    border: 1px solid #535252;
    width: 200px;
    text-align: center;
   
    border-radius: 4px;
}
.user-children-30{
  display: inline-block;
}
.user-tree{
  text-align: center;
}
#mainContainer{
  /* min-width:850px; */
  margin-top: 100px;
}
.containers{
  text-align:center;
  margin:10px .5%;
  padding:10px .5%;

  float:left;
  overflow:visible;
  position:relative;
}


.member{   
  position:relative;
  z-index:   1;
  cursor:default;
  border-bottom:solid 1px #fbf0c278;

}
.member:after{
  display: block;
  position: absolute;
  left: 50%;
  width: 1px;
  height: 25px;
  background: #fbf0c2;
  content: " ";
  bottom: 100%;
  opacity: .5;
}
.member:hover{
z-index:   2;
}
.metaInfo {
  background: #FBF0C2;
  padding: 5px;
  width: 100px;
  margin: 0px auto;

}
.member:hover > .metaInfo:first-child{
  display:block;   
}
.member > .metaInfo img{
width:50px;
height:50px; 
display:inline-block; 
padding:5px;
margin-right:5px;
  vertical-align:top;
border:solid 1px #aaa;
}
.metaInfo p {
  font-family: 'Roboto-Medium';
  color: #1B1B1B;
  font-size: 18px;
  margin: 5px 0px;
  text-transform: capitalize;
}
.metaInfo span {
  background-color: #ffd452;
  border: 1px solid #ffd452;
  color: #1B1B1B;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 5px;
  display: inline-block;
}

/* UserTree.css */

#mainContainer {
  width: 100%;
  padding: 20px;
}

.levelTable {
  margin-bottom: 20px;
}

.levelTable table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.levelTable th, .levelTable  td {
  border: 1px solid #5757574d;
  padding: 8px;
  text-align: center;
}

.levelTable th {
  background-color: #ffd452;
}

.levelTable button {
  padding: 5px 10px;
  font-size: 14px;
  border: 0px;
  background-color: #ffd452;
  color: #000;
  font-weight: 600;
}
.levelTable span {
  background-color: #fbf0c2;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.mainUserHeading h2 {
  color: #ffd452;
  font-size: 60px;
  text-align: center;
}
.levelTable {
  padding: 10px;
  background: #232323;
  border-radius: 20px;
}
.levelTable h3 {
  align-items: center;
  background: #3e3d38;
  background: linear-gradient(90deg, #3e3d38 10%, #282828 50%, #4b442f 90%);
  border: 1px solid #292929;
  border-radius: 13px;
  color: #ffd452;
  display: flex;
  font-size: 40px;
  height: 60px;
  justify-content: center;
  padding-top: 10px;
  text-align: center;
}
.levelTable td {
  color: #fbf0c2;
  text-transform: capitalize;
}

@media (min-width: 320px) and (max-width: 767px) {
  #mainContainer {
    padding: 0px;
  }
  .mainUserHeading h2 {
    font-size: 40px;
  }
  .levelTable h3 {
    font-size: 30px;
    height: 50px;
  }
  .levelTable table {
    table-layout: auto;
  }
  .levelTable {
    overflow-x: auto;
  }
}