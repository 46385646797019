.homeBannerSec {
    background-image:  url('../../images/slider-bg.webp');
    padding-top: 100px;
    background-repeat: no-repeat;
    background-size: 100% 99.7%;
    overflow: hidden;
    position: relative;
}
.homeBannerSec .owl-dots {
    display: none;
}
.homeBannerSec .row {
    align-items: center;
}
.homeBannerSec h1 {
    font-size: 180px;
    line-height: 125px;
    text-transform: uppercase;
    margin-bottom: 90px;
}
.homeBannerSec .owl-item.active .bannerLeftCol h1 {
    position: relative;
    animation: text-left-in 1s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 5s;
    left: -600px;
}
@keyframes text-left-in {
    from {
        left: -600px;
    }
    to {
        left: 0px;
    }
}
.homeBannerSec .owl-item.active .bannerRightCol h1 {
    position: relative;
    animation: text-right-in 1s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 6s;
    left: 600px;
}
@keyframes text-right-in {
    from {
        left: 600px;
    }
    to {
        left: -220px;
    }
}
.homeBannerSec .owl-item.active .bannerRightCol p {
    position: relative;
    animation: pera-right-in 1s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 7s;
    left: 600px;
}
@keyframes pera-right-in {
    from {
        left: 600px;
    }
    to {
        left: 0px;
    }
}
.homeBannerSec .bannerCenterCol {
    text-align: center;
    padding-top: 60px;
}
.homeBannerSec .owl-item .bannerCenterCol img {
    width: auto;
    margin: 0px auto;
    position: relative;
    animation: slider-img-zoom-in 1s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 4s;
}
@keyframes slider-img-zoom-in {
    from {
        bottom: -900px;
        transform: scale(0, 0);
    }
    to {
        transform: scale(1, 1);
        bottom: 0px;
    }
}
.bannerLeftCol {
    position: relative;
    right: -110px;
    z-index: 1;
}
.bannerRightCol h1 {
    position: relative;
    left: -220px;
    z-index: -1;
    top: 50px;
}
.homeBannerSec .owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    top: 40%;
}
.homeBannerSec .owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
}
.homeBannerSec button.owl-next {
    right: -250px;
    animation: arrow-right-in 1s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 9s;
}
@keyframes arrow-right-in {
    from {
        right: -250px;
    }
    to {
        right: 0px;
    }
}
.homeBannerSec button.owl-next::before {
    content: url('../../images/right-arrow.webp');
}
.homeBannerSec button.owl-prev {
    left: -250px;
    animation: arrow-left-in 1s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 8s;
}

@keyframes arrow-left-in {
    from {
        left: -250px;
    }
    to {
        left: 0px;
    }
}
.homeBannerSec button.owl-prev::before {
    content: url('../../images/left-arrow.webp');
}
.homeBannerSec .owl-theme .owl-nav button span {
    display: none;
}
.homeBannerSec .bannerCenterCol::before {
    content: '';
    background-image: url('../../images/animated-lines.webp');
    display: block;
    max-width: 1950px;
    height: 900px;
    position: absolute;
    left: -80px;
    background-size: cover;
    background-repeat: no-repeat;
    top: -32%;
    width: 100%;
    animation: spin-img-zoom-in 1s normal forwards ease-in-out;
    animation-iteration-count: 1;
    animation-delay: 10s;
    rotate: -90deg;
    opacity: 0;
}
@keyframes spin-img-zoom-in {
    from {
        bottom: -900px;
        transform: scale(0, 0);
        opacity: 1;
        rotate: -50deg;
    }
    to {
        transform: scale(1, 1);
        bottom: 0px;
        opacity: 1;
        rotate: 0deg;
    }
}

/* without animation banner css */

.homeBannerSec2 {
/*    background-image: url('../../images/slider-bg.webp');*/
    /*padding-top: 100px;
    background-repeat: no-repeat;
    background-size: 100% 99.7%;
    overflow: hidden;
    position: relative;*/
}
.homeBannerSec2 .owl-dots {
    display: none;
}
.homeBannerSec2 .row {
    align-items: center;
}
.homeBannerSec2 h1 {
    font-size: 180px;
    line-height: 125px;
    text-transform: uppercase;
    margin-bottom: 90px;
}
.homeBannerSec2 .owl-item.active .bannerLeftCol h1 {
    position: relative;
    /* animation: text-left-in 1s normal forwards ease-in-out; */
    animation-iteration-count: 1;
    animation-delay: 5s;
    /* left: -600px; */
}
@keyframes text-left-in {
    from {
        left: -600px;
    }
    to {
        left: 0px;
    }
}
.homeBannerSec2 .owl-item.active .bannerRightCol h1 {
    position: relative;
    /* animation: text-right-in 1s normal forwards ease-in-out; */
    animation-iteration-count: 1;
    animation-delay: 6s;
    /* left: 600px; */
}
@keyframes text-right-in {
    from {
        left: 600px;
    }
    to {
        left: -220px;
    }
}
.homeBannerSec2 .owl-item.active .bannerRightCol p {
    position: relative;
    /* animation: pera-right-in 1s normal forwards ease-in-out; */
    animation-iteration-count: 1;
    animation-delay: 7s;
    /* left: 600px; */
}
@keyframes pera-right-in {
    from {
        left: 600px;
    }
    to {
        left: 0px;
    }
}
.homeBannerSec2 .bannerCenterCol {
    text-align: center;
    padding-top: 60px;
}
.homeBannerSec2 .owl-item .bannerCenterCol img {
    width: auto;
    margin: 0px auto;
    position: relative;
    /* animation: slider-img-zoom-in 1s normal forwards ease-in-out; */
    animation-iteration-count: 1;
    animation-delay: 4s;
}
@keyframes slider-img-zoom-in {
    from {
        bottom: -900px;
        transform: scale(0, 0);
    }
    to {
        transform: scale(1, 1);
        bottom: 0px;
    }
}
.bannerLeftCol {
    position: relative;
    right: -110px;
    z-index: 1;
}
.bannerRightCol h1 {
    position: relative;
    left: -220px;
    z-index: -1;
    top: 50px;
}
/*.homeBannerSec2 .owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    top: 40%;
}*/
.homeBannerSec2 .owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
}
/*.homeBannerSec2 .owl-theme .owl-nav .owl-prev {
    left: 160px;
}

.homeBannerSec2 .owl-theme .owl-nav .owl-next {
    right: 163px;
}*/


.owl-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 1600px;
    margin: 0px auto 0px;
    left: 0px;
    right: 0px;
}
.homeBannerSec2 button.owl-next {
    right: 0px;
    /* animation: arrow-right-in 1s normal forwards ease-in-out; */
    animation-iteration-count: 1;
    animation-delay: 9s;
}
@keyframes arrow-right-in {
    from {
        right: -250px;
    }
    to {
        right: 0px;
    }
}
.homeBannerSec2 button.owl-next::before {
    content: url('../../images/right-arrow.webp');
}
.homeBannerSec2 button.owl-prev {
    left: 0px;
    /* animation: arrow-left-in 1s normal forwards ease-in-out; */
    animation-iteration-count: 1;
    animation-delay: 8s;
}

@keyframes arrow-left-in {
    from {
        left: -250px;
    }
    to {
        left: 0px;
    }
}
.homeBannerSec2 button.owl-prev::before {
    content: url('../../images/left-arrow.webp');
}
.homeBannerSec2 .owl-theme .owl-nav button span {
    display: none;
}
.homeBannerSec2 .bannerCenterCol::before {
    content: '';
    background-image: url('../../images/animated-lines.webp');
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: -75px -200px;
}
.item.oneSlide {
    position: relative;
}
@keyframes spin-img-zoom-in {
    from {
        bottom: -900px;
        transform: scale(0, 0);
        opacity: 1;
        rotate: -50deg;
    }
    to {
        transform: scale(1, 1);
        bottom: 0px;
        opacity: 1;
        rotate: 0deg;
    }
}

body {
    overflow-x: hidden;
}
.loader {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
    bottom: 0px;
    background-color: #000;
    text-align: center;
}

.loader img {
    position: relative;
    top: 18%;
}
@keyframes loader-zoom-in {
    0% {
        transform: scale(1, 1);
        opacity: 1;
        visibility: visible;
    }
    50% {
        transform: scale(1.5, 1.5);
        opacity: 1;
        visibility: visible;
    }
    80% {
        transform: scale(2, 2);
        opacity: 1;
    }
    100% {
        transform: scale(4, 4);
        opacity: 0;
        visibility: hidden;
    }
}
.hideDextop {
    display: none;
}

.homeBannerSec2 .item.EarnBanner {
    background-image: url('../../images/earn_banner_bg.png');
    background-size: cover;
 }
.earnLeftCol h1 {
    margin-bottom: 0px;
    font-size: 160px;
}
.earnLeftCol p {
    max-width: 420px;
    color: #000000;
}
.earnLeftCol {
    position: relative;
    right: -200px;
}
.earnColumnImg {
    position: relative;
    /* padding-top: 40px; */
}
.earnColumnImg img {
    position: relative;
    z-index: 2;
}
.earnColumnImg:after {
    content: '';
    position: absolute;
    top: -5%;
    left: -5%;
    z-index: 1;
    mix-blend-mode: color-dodge;
    background-image: url('../../images/flame-smock.gif');
    background-size: cover;
    width: 100%;
    height: 100%;
}
.homeBannerSec2 .item.unlimitedReferralBanner {
    background-image: url('../../images/unlimitedReferralBg.png');
    background-size: cover;
 }
 .unlimitedReferralImg {
    /* padding-top: 40px; */
}
.owl-carousel .owl-item .EarnBanner img,
.owl-carousel .owl-item .unlimitedReferralBanner img {
    width: auto;
}

@media (min-width: 320px) and (max-width: 767px) {
    .bannerLeftCol {
        right: 0px;
    }
    .homeBannerSec .owl-item.active .bannerLeftCol h1{
        animation: none;
        left: 0px;
        padding-top: 20px;
        margin-bottom: 0px;
    }
    .homeBannerSec h1 {
        font-size: 80px;
        line-height: 56px;
        margin-bottom: 20px;
    }
    .homeBannerSec .owl-item.active .bannerRightCol h1 {
        animation: none;
        left: 0px;
    }
    .bannerRightCol h1 {
        left: 0px;
        top: 0px;
    }
    .homeBannerSec .bannerCenterCol {
        padding-top: 0px;
    }
    .homeBannerSec .owl-item .bannerCenterCol img {
        width: 100%;
        animation: none;
        bottom: -30px;
    }
    .homeBannerSec::before {
        content: none;
    }
    .homeBannerSec .owl-item.active .bannerRightCol p {
        animation: none;
        left: 0px;
    }
    .homeBannerSec {
        background: rgb(254, 213, 85);
        background: linear-gradient(90deg, rgba(254, 213, 85, 1) 0%, rgba(254, 218, 100, 1) 50%, rgba(240, 198, 73, 1) 100%);
    }
    .homeBannerSec .col-md-6 {
        order: 2;
    }
    .homeBannerSec .col-md-3 {
        order: 1;
        text-align: center;
    }
    .homeBannerSec .container {
        margin: 0px auto;
    }
    .homeBannerSec .owl-theme .owl-nav {
        margin-top: 0px;
    }
    .homeBannerSec .owl-theme .owl-nav [class*='owl-'] {
        top: 50%;
        animation: none;
    }
    .homeBannerSec button.owl-prev {
        left: 0px;
    }
    .homeBannerSec button.owl-next {
        right: 0px;
    }
    .bannerCenterCol::before {
        display: none;
    }
    .hideDextop {
        display: block;
    }
    .hideRisponsive {
        display: none;
    }
    .loader {
        display: none;
    }
    .scrollDown img {
        max-width: 120px;
    }


    .forSlidStyle .bannerCenterColBefore {
        left: 0px !important;
    }
    .forSlidStyle .bannerLeftCol {
        padding-top: 20px !important;
        text-align: center;
    }
    .homeBannerSec2 .forSlidStyle .bannerCenterColBefore img {
        width: 100% !important;
    }
    .forSlidStyle .bannerLeftCol p {
        margin: 0px auto 0px;
    }


    .slideLast h1 {
        max-width: 375px;
        margin: 0px auto 0px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .bannerLeftCol {
        right: 0px;
    }
    .homeBannerSec .owl-item.active .bannerLeftCol h1 {
        animation: none;
        left: 0px;
        padding-top: 20px;
        margin-bottom: 0px;
    }
    .homeBannerSec h1 {
        font-size: 80px;
        line-height: 56px;
        margin-bottom: 20px;
    }
    .homeBannerSec  .owl-item.active .bannerRightCol h1 {
        animation: none;
        left: 0px;
    }
    .bannerRightCol h1 {
        left: 0px;
        top: 0px;
    }
    .homeBannerSec .owl-item .bannerCenterCol img {
        width: 100%;
        animation: none;
    }
    .homeBannerSec::before {
        content: none;
    }
    .homeBannerSec .owl-item.active .bannerRightCol p {
        animation: none;
        left: 0px;
    }
    .homeBannerSec {
        background: rgb(254, 213, 85);
        background: linear-gradient(90deg, rgba(254, 213, 85, 1) 0%, rgba(254, 218, 100, 1) 50%, rgba(240, 198, 73, 1) 100%);
    }
    .homeBannerSec .col-md-6 {
        order: 2;
        width: 50%;
    }
    .homeBannerSec .col-md-3 {
        order: 1;
        width: 100%;
        text-align: center;
    }
    .homeBannerSec .container {
        margin: 0px auto;
    }
    .homeBannerSec .owl-theme .owl-nav {
        margin-top: 0px;
    }
    .homeBannerSec .owl-theme .owl-nav [class*='owl-'] {
        top: 50%;
        animation: none;
    }
    .homeBannerSec button.owl-prev {
        left: 0px;
    }
    .homeBannerSec button.owl-next {
        right: 0px;
    }
    .homeBannerSec .row {
        justify-content: center;
    }
    .homeBannerSec .bannerCenterCol::before {
        display: none;
    }
    .loader {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .homeBannerSec .owl-item .bannerCenterCol img {
        width: 100%;
    }
    .homeBannerSec h1 {
        font-size: 100px;
        line-height: 70px;
        margin-bottom: 60px;
    }
    .bannerLeftCol {
        right: -70px;
    }
    .homeBannerSec::before {
        top: -42%;
    }
    @keyframes text-right-in {
        from {
            left: 600px;
        }
        to {
            left: -120px;
        }
    }
    .homeBannerSec .owl-theme .owl-nav {
        margin-top: 5px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
    }
    .homeBannerSec .container {
        margin: 0px auto;
    }
    .homeBannerSec .bannerCenterCol::before {
        height: 630px;
        top: -25%;
        left: -130px;
    }
    .loader img {
        top: -150px;
    }
    
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .homeBannerSec h1 {
      font-size: 110px;
      line-height: 77px;
    }
    @keyframes text-right-in {
        from {
            left: 600px;
        }
        to {
            left: -125px;
        }
    }
    .homeBannerSec .owl-item .bannerCenterCol img {
        width: 100%;
    }
    .homeBannerSec .bannerCenterCol::before {
        height: 750px;
        left: -150px;
        top: -26%;
    }
    @keyframes pera-right-in {
        from {
            left: 600px;
        }
        to {
            left: -30px;
        }
    }
    .loader img {
        width: 100%;
        mix-blend-mode: multiply;
        position: relative;
        top: -200px;
    }

    .earnLeftCol {
        right: -100px;
    }
    .owl-carousel .owl-item .EarnBanner img, .owl-carousel .owl-item .unlimitedReferralBanner img {
        width: 100%;
    }
    .earnColumnImg:after {
        top: -120px;
    }

    
}

@media (min-width: 1400px) and (max-width: 1699px) {
    .homeBannerSec h1 {
      font-size: 140px;
      line-height: 98px;
    }
    .bannerLeftCol {
        right: -75px;
    }
    .homeBannerSec .bannerCenterCol::before {
        height: 800px;
        left: -120px;
        top: -23%;
    }
    @keyframes text-right-in {
        from {
            left: 600px;
        }
        to {
            left: -130px;
        }
    }
}



/* without animation banner css */

@media (min-width: 320px) and (max-width: 767px) {

    .homeBannerSec2 .owl-item.active .bannerLeftCol h1{
        animation: none;
        left: 0px;
        padding-top: 20px;
        margin-bottom: 0px;
    }
    .homeBannerSec2 h1 {
        font-size: 80px;
        line-height: 65px;
    }
    .earnLeftCol {
        right: 0px;
        margin-top: 50px;
    }
    
    .owl-carousel .owl-item .EarnBanner img, .owl-carousel .owl-item .unlimitedReferralBanner img {
        width: 100%;
    }
    .homeBannerSec2 .owl-item.active .bannerRightCol h1 {
        animation: none;
        left: 0px;
    }
    .homeBannerSec2 .bannerCenterCol {
        padding-top: 0px;
    }
    .homeBannerSec2 .owl-item .bannerCenterCol img {
        width: 100%;
        animation: none;
        bottom: -17px;
    }
    .homeBannerSec2::before {
        content: none;
    }
    .homeBannerSec2 .owl-item.active .bannerRightCol p {
        animation: none;
        left: 0px;
    }
    /*.homeBannerSec2 {
        background: rgb(254, 213, 85);
        background: linear-gradient(90deg, rgba(254, 213, 85, 1) 0%, rgba(254, 218, 100, 1) 50%, rgba(240, 198, 73, 1) 100%);
    }*/
    .homeBannerSec2 .col-md-6 {
        order: 2;
    }
    .homeBannerSec2 .col-md-3 {
        order: 1;
        text-align: center;
    }
    .homeBannerSec2 .container {
        margin: 0px auto;
    }
    .homeBannerSec2 .owl-theme .owl-nav {
        margin-top: 0px;
        display: none;
    }
    .homeBannerSec2 .owl-theme .owl-nav [class*='owl-'] {
        top: 50%;
        animation: none;
    }
    .homeBannerSec2 button.owl-prev {
        left: 0px;
    }
    .homeBannerSec2 button.owl-next {
        right: 0px;
    }
    .homeBannerSec2 .item {
        border-radius: 0px 0px 32px 32px !important;
    }
    .forSlidStyle .row {
        flex-direction: column-reverse;
    }


    .homeBannerSec2 .slideLast {
        padding-top: 0px !important;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
    }
    .homeBannerSec2 .slideLast .bannerLeftCol {
        right: 0px !important;
        text-align: center;
    }
    .slideLast .bannerLeftCol p {
        padding-left: 0px !important;
        margin: 0px auto 0px;
    }
    .slideLast .row {
        flex-direction: column-reverse;
        gap: 40px;
    }
    .homeBannerSec2 .slideLast .bannerCenterColBefore img {
        bottom: 0px !important;
        width: auto !important;
        height: 100% !important;
        max-height: 350px !important;
        left: 0px;
        margin: 0px auto 0px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .homeBannerSec2 .owl-item.active .bannerLeftCol h1 {
        animation: none;
        left: 0px;
        padding-top: 20px;
        margin-bottom: 0px;
    }
    .homeBannerSec2 h1 {
        font-size: 80px;
        line-height: 60px;
    }
    .homeBannerSec2  .owl-item.active .bannerRightCol h1 {
        animation: none;
        left: 0px;
    }
    .homeBannerSec2 .owl-item .bannerCenterCol img {
        width: 100%;
        animation: none;
    }
    .homeBannerSec2::before {
        content: none;
    }
    .homeBannerSec2 .owl-item.active .bannerRightCol p {
        animation: none;
        left: 0px;
    }
    .homeBannerSec2 {
        background: rgb(254, 213, 85);
        background: linear-gradient(90deg, rgba(254, 213, 85, 1) 0%, rgba(254, 218, 100, 1) 50%, rgba(240, 198, 73, 1) 100%);
    }
    .homeBannerSec2 .col-md-6 {
        order: 2;
        width: 50%;
    }
    .homeBannerSec2 .col-md-3 {
        order: 1;
        width: 100%;
        text-align: center;
    }
    .homeBannerSec2 .container {
        margin: 0px auto;
    }
    .homeBannerSec2 .owl-theme .owl-nav {
        margin-top: 0px;
    }
    .homeBannerSec2 .owl-theme .owl-nav [class*='owl-'] {
        top: 50%;
        animation: none;
    }
    .homeBannerSec2 button.owl-prev {
        left: 0px;
    }
    .homeBannerSec2 button.owl-next {
        right: 0px;
    }
    .homeBannerSec2 .row {
        justify-content: center;
    }
    .homeBannerSec2 .bannerCenterCol::before {
        display: none;
    }
    .homeBannerSec2 .bannerCenterCol {
        padding-top: 10px;
    }
    .hideRisponsive {
        display: none;
    }
    .hideDextop {
        display: block;
    }


    .item.slideLast {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slideLast .bannerLeftCol h1 {
        text-align: left !important;
    }
    .slideLast .bannerLeftCol p {
        text-align: left !important;
    }

    .owl-carousel .owl-item .EarnBanner img, .owl-carousel .owl-item .unlimitedReferralBanner img {
        width: 100%;
    }
    .earnLeftCol {
        right: -20px;
    }

}

@media (min-width: 992px) and (max-width: 1199px) {
    .homeBannerSec2 .owl-item .bannerCenterCol img {
        width: 100%;
    }
    .homeBannerSec2 h1 {
        font-size: 100px;
        line-height: 75px;
    }
    .earnLeftCol p {
        max-width: 330px;
    }
    .earnColumnImg:after {
        top: -2%;
        left: -2%;
    }
    .homeBannerSec2::before {
        top: -42%;
    }
    .homeBannerSec2 .owl-item.active .bannerRightCol h1{
        margin-left: 100px;
    }
    .homeBannerSec2 .owl-theme .owl-nav {
        margin-top: 5px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
    }
    .homeBannerSec2 .container {
        margin: 0px auto;
    }
    .homeBannerSec2 .bannerCenterCol::before {
        height: 630px;
        top: -25%;
        left: -130px;
    }
    
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .homeBannerSec2 h1 {
      font-size: 110px;
      line-height: 80px;
    }
    .homeBannerSec2 .owl-item .bannerCenterCol img {
        width: 100%;
        position: relative;
    }
    .homeBannerSec2 .owl-item.active .bannerRightCol h1{
        margin-left: 95px;
    }
    .homeBannerSec2 .owl-item.active .bannerRightCol p{
        margin-left: -25px;
        width: 92%;
    }
    .homeBannerSec2 .bannerCenterCol::before {
        /*height: 750px;
        left: -150px;
        top: -26%;*/
    }
    .item.forSlidStyle > .container {
        height: 100%;
    }
    .item.forSlidStyle > .container .row {
        height: 100%;
    }
    .slideLast img {
        left: 210px !important;
        bottom: 35px !important;
        height: 580px !important;
        object-fit: scale-down !important;
    }
    .owl-nav {
        max-width: 1140px;
    }
    
}

@media (min-width: 1400px) and (max-width: 1699px) {
    .homeBannerSec2 h1 {
      font-size: 140px;
      line-height: 98px;
    }
    .homeBannerSec2 .owl-item.active .bannerRightCol h1{
        margin-left: 85px;
    }
    .owl-nav {
        max-width: 1320px;
    }
    .homeBannerSec2 .bannerCenterCol::before {
        background-position: -70px -140px;
    }
    .item.forSlidStyle > .container {
        height: 100%;
    }
    .item.forSlidStyle > .container .row {
        height: 100%;
    }
    /*.forSlidStyle .bannerCenterColBefore img {
        max-width: 586px;
    }*/
   /* .forSlidStyle .bannerCenterColBefore {
        left: 80px;
    }*/
    /*.forSlidStyle .bannerLeftCol {
        right: -12px !important;
    }*/
    .item.oneSlide img {
        height: 700px;
    }
    .slideLast img {
        left: 185px;
        bottom: 30px;
        height: 620px;
    }
   
}





/* For Silde Banner */


.bannerCenterColBefore::before {
    background-image: none !important;
}
.forSlidStyle {
    padding-top: 160px !important;
    padding-bottom: 100px;
}
.forSlidStyle .bannerLeftCol {
    right: 0px !important;
    padding-top: 68px;
}
.forSlidStyle .bannerLeftCol h1 {
    width: 100%;
    max-width: 585px;
    margin: 0px;
    margin-bottom: 24px;
}
.forSlidStyle .bannerCenterColBefore {
    padding: 0px;
/*    background-image: url(http://localhost:3000/static/media/slider-bg.553867995597b1f2fddd.png);*/
    position: relative;
    left: 95px;
}
.forSlidStyle .bannerCenterColBefore img {
    width: auto !important;
}
.forSlidStyle .bannerLeftCol p {
    width: 100%;
    max-width: 380px;
}


.slideLast {
    padding-top: 200px !important;
    padding-bottom: 40px;
}
.slideLast .bannerCenterColBefore {
    padding-top: 0px;
/*    background-image: url(http://localhost:3000/static/media/slider-bg.553867995597b1f2fddd.png);*/
}
.slideLast .bannerCenterColBefore img {
    margin-right: 0px !important;
}
.slideLast .bannerLeftCol {
    right: 45px !important;
    padding-top: 47px;
}
.slideLast .bannerLeftCol p {
    width: 100%;
    max-width: 420px;
    margin-bottom: 0px;
    padding-left: 34px;
}
.slideLast .bannerLeftCol h1 {
    margin-bottom: 38px;
}


@media (min-width: 1200px) and (max-width: 1440px) {

    .slideLast .bannerLeftCol h1 {
        padding-right: 22px;
    }
    .slideLast .bannerLeftCol {
        padding-top: 0px;
    }
    /*.slideLast .bannerCenterColBefore img {
        height: 673px !important;
        width: auto !important;
    }*/
    .forSlidStyle .bannerLeftCol h1 {
        max-width: 386px;
        margin-bottom: 25px;
    }
    .forSlidStyle .bannerCenterColBefore {
        left: 45px;
    }
    .forSlidStyle .bannerCenterColBefore img {
        width: 100% !important;
        max-width: 460px;
        left: 40px;
    }
}


@media (min-width: 1441px) and (max-width: 1699px) {

    .forSlidStyle .bannerLeftCol h1 {
        max-width: 498px;
        margin-bottom: 24px;
    }
    .forSlidStyle .bannerCenterColBefore {
        left: -10px;;
    }

}


@media (min-width: 992px) and (max-width: 1199px) {

    .slideLast .bannerCenterColBefore img {
        height: 430px;
        width: auto !important;
        left: 120px;
        bottom: 20px;
    }
    .forSlidStyle .bannerCenterColBefore {
        left: 40px;   

    }
    .forSlidStyle .bannerCenterColBefore img {
        width: 100% !important;
    }
    .forSlidStyle .bannerLeftCol h1 {
        max-width: 340px;
        margin-bottom: 24px;
    }
    .slideLast {
        padding-top: 79px;
    }
    .forSlidStyle {
        padding-top: 60px;
    }
    .homeBannerSec2 .item {
        border-radius: 0px 0px 32px 32px !important;
    }

    .owl-carousel .owl-item .EarnBanner img, .owl-carousel .owl-item .unlimitedReferralBanner img {
        width: 100%;
    }
    .earnLeftCol {
        right: -70px;
    }
}


@media (min-width: 768px) and (max-width: 991px) {


    .slideLast .bannerCenterColBefore img {
        width: auto !important;
        height: 450px;
        left: 100px;
        bottom: 15px;
    }
    .slideLast .bannerLeftCol {
        padding-top: 0px;
    }
    .forSlidStyle .bannerCenterColBefore {
        left: 35px;
    }
    .forSlidStyle .bannerLeftCol h1 {
        max-width: 280px;
        padding-top: 0px !important;
        margin-bottom: 24px !important;
    }
    .forSlidStyle .bannerCenterColBefore img {
        width: 100% !important;
        left: 0px !important;
    }
    .forSlidStyle .bannerLeftCol h1 {
        max-width: 280px;
        padding-top: 35px !important;
        margin-bottom: 24px !important;
    }
    .forSlidStyle {
        padding-top: 60px !important;
        display: flex;
        align-items: center;
    }
    .forSlidStyle .bannerLeftCol p {
        max-width: 417px;
        text-align: left !important;
        margin: 0px auto 0px;
    }
    .homeBannerSec2 .item {
        border-radius: 0px 0px 32px 32px !important;
    }
}



@media (min-width: 320px) and (max-width: 440px) {


    /*.forSlidStyle .bannerCenterColBefore {
        left: 0px;

    }
    .forSlidStyle .bannerCenterColBefore img {
        bottom: 0px !important;
        width: 100% !important;
    }
    .forSlidStyle .bannerLeftCol {
        padding-top: 0px;
        margin-bottom: 30px;
    }
    .forSlidStyle {
        padding-top: 0px;
    }
    .forSlidStyle > .row {
        flex-direction: column-reverse;
    }
    .forSlidStyle .bannerLeftCol h1 {
        max-width: 296px;
        text-align: center;
        margin: 0px auto 0px;
    }
    .forSlidStyle .bannerLeftCol p {
        max-width: 296px;
        margin: 20px auto 0px;
        text-align: center;
    }
    .slideLast > .row {
        flex-direction: column-reverse;
    }
    .slideLast .bannerCenterColBefore img {
        bottom: 0px !important;
        width: auto !important;
        margin: 0px auto 0px !important;
        height: 100% !important;
        max-height: 320px !important;
        left: 0px;
    }
    .slideLast .bannerLeftCol {
        right: 0px !important;
        padding-top: 0px;
    }
    .slideLast {
        padding-top: 100px !important;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
    }
    .slideLast .row {
        flex-direction: column-reverse;
        gap: 40px;
    }
    .slideLast h1 {
        text-align: center;
        max-width: 296px;
        margin: 0px auto 0px;
    }
    .slideLast .bannerLeftCol p {
        padding-left: 0px;
        max-width: 296px;
        margin: 20px auto 0px;
        text-align: center;
    }*/
}






.homeBannerSec2 .item {
    background-image: linear-gradient(45deg, #FFD452, #FFDC65, #E5B634);
    border-radius: 0px 0px 64px 64px;
    /* padding-top: 130px; */
}
.homeBannerSec2 .owl-stage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
}
.homeBannerSec2 .owl-stage .item {
    height: 100%;
}
.bannerRightCol {
    z-index: 99;
    position: relative;
}

.slideLast img {
    left: 215px;
    bottom: 50px;
    height: 700px;
    object-fit: cover;
    mix-blend-mode: multiply;
}
.slideLast:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-image: url('../../images/slide-3.webp');
    position: absolute;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    mix-blend-mode: multiply;
    border-radius: 0px 0px 64px 64px;
}
.slideLast {
    position: relative;
}