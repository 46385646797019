.ReferralUsers {
    background-image: url('../../images/referral_user_bg.png');
    background-color: #1B1B1B;
    padding: 120px 0px;
    background-size: 100% 100%;
    position: relative;
}
.ReferralUsersTitle h3 {
    color: #FFD452;
    font-size: 72px;
    text-align: center;
    margin-bottom: 30px;
}
.UserTree img {
    width: 100%;
}

@media (min-width: 320px) and (max-width: 767px) {
    .ReferralUsers {
        padding: 70px 0px;
    }
    .PackegAmount {
        margin-top: 30px;
    }
}